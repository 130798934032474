import { FC } from 'react'

import {
  FindLearnerCategoryListQuery,
  FindLearnerCategoryListQueryVariables,
} from 'types/graphql'

import { CellSuccessProps } from '@redwoodjs/web'

import { default as LoadingSpinner } from 'src/components/Library/Loading'

import CourseCategories from './CourseCategories/CourseCategories'

// Nav Tab Items

export const QUERY = gql`
  query FindLearnerCategoryListQuery(
    $reportingFlags: ReportingFlags!
    $membershipIds: [Int!]
  ) {
    learnerCategories: learnerCategoriesByClient(status: PUBLISHED) {
      id
      name
      aclPrincipals {
        principalType
        principal {
          __typename
          ... on ACLPrincipalMembershipGroup {
            membershipGroup {
              id
              name
            }
          }
        }
      }
      learnerCourses(status: PUBLISHED) {
        id
        name
        heroUrl
        learnerActivities(status: PUBLISHED) {
          id
          name
          learnerTasks(status: PUBLISHED) {
            id
            name
          }
        }
        reporting(
          reportingFlags: $reportingFlags
          membershipIds: $membershipIds
        ) {
          learnerCourseId
          attempts {
            status
            percentage
            relatedTracking {
              ... on LearnerCourseTracking {
                id
                membershipId
              }
            }
          }
        }
      }
    }
  }
`

export const Loading = () => (
  <div
    className="flex items-center justify-center"
    style={{ minHeight: 'calc(100vh - 80px)' }}
  >
    <LoadingSpinner />
  </div>
)

export const Failure = ({ error }) => (
  <div style={{ color: 'red' }}>Error: {error.message}</div>
)

export const afterQuery = (props) => props
type Props = CellSuccessProps<
  FindLearnerCategoryListQuery,
  FindLearnerCategoryListQueryVariables
> & {
  localStorageName: string
  membershipGroupIds: number[]
}
export type CourseLearnerCategory = Props['learnerCategories'][0]
export const Success: FC<Props & { id: string }> = ({
  id,
  learnerCategories,
  localStorageName,
  membershipGroupIds,
}) => {
  return (
    <CourseCategories
      id={Number(id)}
      learnerCategories={learnerCategories}
      localStorageName={localStorageName}
      membershipGroupIds={membershipGroupIds}
    />
  )
}
