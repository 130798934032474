import { FC } from 'react'

import { FLAG_FEATURES } from 'api/src/common/enums'

import { Metadata } from '@redwoodjs/web'

import CourseCategoriesCell from 'src/components/Learner/Courses/CourseCategoriesCell'
import FeatureDenied from 'src/components/Library/FeatureDenied/FeatureDenied'
import PageHeader from 'src/components/PageHeader/PageHeader'
import FeatureToggle from 'src/lib/hooks/LiveFeatureFlag/FeatureToggle'
import useReadLocalStorage from 'src/lib/hooks/UseReadLocalStorage'
import { useAuth } from 'web/src/Providers'

const LearnerCategoriesPage: FC<{ id: string }> = (props) => {
  const { currentUser } = useAuth()

  const membershipId = currentUser.userData.activeMembershipId

  const localStorageName = `membershipId-${membershipId}-learnerSelectedGroups`
  const storedValue =
    useReadLocalStorage<{ value: number }[]>(localStorageName) || []
  const membershipGroupIds = storedValue.map((group) => group.value)

  return (
    <>
      <Metadata title="Learner Courses" description="Learner Courses page" />
      <PageHeader
        title="Learner Courses"
        parentDataTestId="learn-categories-courses-title"
      />
      <FeatureToggle
        feature={FLAG_FEATURES.LEARNER_COURSES}
        InactiveComponent={<FeatureDenied />}
      >
        <CourseCategoriesCell
          id={props.id}
          localStorageName={localStorageName}
          membershipIds={
            !currentUser.isClientAlias
              ? [currentUser.userData.activeMembershipId]
              : []
          }
          membershipGroupIds={membershipGroupIds}
          reportingFlags={{
            includeCourseStats: false,
            includeCourseAttempts: true,
            includeTaskStats: false,
            includeTaskType: false,
            lastAttempt: true,
            includeArchived: false,
          }}
        />
      </FeatureToggle>
    </>
  )
}

export default LearnerCategoriesPage
