import { FC, useCallback, useEffect, useState } from 'react'

import { ACLPrincipalMembershipGroup } from 'types/graphql'
import { useMediaQuery } from 'usehooks-ts'

import Empty from 'src/components/Empty/Empty'
import { useAuth } from 'src/Providers'
import { filterCourses } from 'src/Util'

import FilterNavTabs from '../../../../FilterNavTabs/FilterNavTabs'
import SearchGroups from '../../../Library/SearchGroups/SearchGroups'
import { CourseLearnerCategory } from '../CourseCategoriesCell'

import CourseItem from './CourseItem/CourseItem'
interface CourseCategoriesProps {
  id: number
  learnerCategories: CourseLearnerCategory[]
  localStorageName: string
  membershipGroupIds?: number[]
}
const CourseCategories: FC<CourseCategoriesProps> = ({
  id,
  learnerCategories,
  localStorageName,
  membershipGroupIds,
}) => {
  const { currentUser } = useAuth()

  // scroll to id if exists
  useEffect(() => {
    if (id) {
      setTimeout(() => {
        const element = document.getElementById('category-' + id)
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' })
        }
      }, 500)
    }
  }, [id])

  const [searchVal, setSearchVal] = useState<string>('')
  const [selectedGroups, setSelectedGroups] = useState<number[]>(
    membershipGroupIds || [],
  )
  const [categoriesForMembership, setCategoriesForMembership] = useState<
    CourseLearnerCategory[]
  >([])
  const [continueCategory, setContinueCategory] =
    useState<CourseLearnerCategory>()
  const [toDoCategories, setTodoCategories] = useState<CourseLearnerCategory[]>(
    [],
  )
  const [inProgressCategories, setInProgressCategories] = useState<
    CourseLearnerCategory[]
  >([])
  const [completedCategories, setCompletedCategories] = useState<
    CourseLearnerCategory[]
  >([])
  const [initialSelectedTab, setInitialSelectedTab] = useState('All')
  const [filteredCategories, setFilteredCategories] = useState<
    CourseLearnerCategory[]
  >([])
  // start by assuming the tab selected has data

  //figure out what tab is supposed to be initially selected

  const isMobile = useMediaQuery('(max-width: 769px)')
  useEffect(() => {
    let filtered = learnerCategories
    if (selectedGroups.length > 0) {
      filtered = learnerCategories.filter((learnerCategory) => {
        const aclEntries = learnerCategory.aclPrincipals.filter(
          ({ principalType }) => principalType === 'MEMBERSHIPGROUP',
        )
        // compare the acl entries to the selected groups, acl entries must include all selected groups
        return selectedGroups.every((selectedGroup) =>
          aclEntries.some(
            ({ principal }) =>
              (principal as ACLPrincipalMembershipGroup).membershipGroup.id ===
              selectedGroup,
          ),
        )
      })
    }
    const categoriesWithFilteredCourses = filterCourses(filtered, searchVal)
    setCategoriesForMembership(
      categoriesWithFilteredCourses as CourseLearnerCategory[],
    )
  }, [learnerCategories, searchVal, selectedGroups])
  //filter any courses by search value
  const [navTabItems, setNavTabItems] = useState([
    { title: 'All', count: 0 },
    { title: 'In Progress', count: 0 },
    { title: 'To Do', count: 0 },
    { title: 'Completed', count: 0 },
  ])

  useEffect(() => {
    let toDoCourseNum = 0,
      inProgressCourseNum = 0,
      completedCourseNum = 0,
      totalCourseNum = 0
    const toDoCat: CourseLearnerCategory[] = [],
      inProgressCat: CourseLearnerCategory[] = [],
      completedCat: CourseLearnerCategory[] = []

    if (!currentUser.isClientAlias) {
      //process the learner categories data to find out what is contained within
      //get counts for each type of course
      //files the respective courses into each type
      //reset to clean state

      const continueCatCourses = []

      for (const category of categoriesForMembership) {
        const toDoCourses = [],
          inProgressCourses = [],
          completedCourses = []

        for (const learnerCourse of category.learnerCourses) {
          const { reporting } = learnerCourse

          //check status of course based upon user course tracking
          if (reporting?.attempts.length > 0) {
            if (reporting?.attempts?.[0]?.status === 'COMPLETED') {
              completedCourses.push(learnerCourse)
            } else if (reporting?.attempts?.[0]?.status === 'IN_PROGRESS') {
              inProgressCourses.push(learnerCourse)
              continueCatCourses.push(learnerCourse) //add to continue learning array
            } else if (reporting?.attempts?.[0]?.status === 'NOT_STARTED') {
              toDoCourses.push(learnerCourse)
            }
          } else {
            toDoCourses.push(learnerCourse)
          }
        }

        toDoCourseNum += toDoCourses.length
        inProgressCourseNum += inProgressCourses.length
        completedCourseNum += completedCourses.length
        totalCourseNum +=
          toDoCourses.length +
          inProgressCourses.length +
          completedCourses.length

        //push courses into respective arrays
        if (toDoCourses.length)
          toDoCat.push({ ...category, learnerCourses: toDoCourses })

        if (inProgressCourses.length)
          inProgressCat.push({
            ...category,
            learnerCourses: inProgressCourses,
          })

        if (completedCourses.length)
          completedCat.push({
            ...category,
            learnerCourses: completedCourses,
          })
      }

      // if there are any continue learning courses, add it to the respect categories for users to see which they still need to completed
      if (continueCatCourses.length) {
        const continueCat: any = {
          id: 'continue-learning',
          name: 'Continue Learning...',
          learnerCourses: continueCatCourses,
        }
        setContinueCategory(continueCat)
      }
      setTodoCategories(toDoCat)
      setInProgressCategories(inProgressCat)
      setCompletedCategories(completedCat)
    } else {
      toDoCourseNum = categoriesForMembership.reduce(
        (acc, cat) => acc + cat.learnerCourses.length,
        0,
      )
      totalCourseNum = toDoCourseNum
    }
    setNavTabItems([
      { title: 'All', count: totalCourseNum },
      { title: 'In Progress', count: inProgressCourseNum },
      { title: 'To Do', count: toDoCourseNum },
      { title: 'Completed', count: completedCourseNum },
    ])
    if (toDoCourseNum <= 0) {
      if (inProgressCourseNum > 0) setInitialSelectedTab('In Progress')
      else if (totalCourseNum > 0) setInitialSelectedTab('All')
      else if (completedCourseNum > 0) setInitialSelectedTab('Completed')
    }
  }, [categoriesForMembership, currentUser.isClientAlias])
  //if not in support mode

  //set the counts for each type

  //set the selected intial tab to the selected one
  const [tabSelected, setTabSelected] = useState(
    navTabItems.findIndex((tabItem) => initialSelectedTab === tabItem.title),
  )

  const mergeAllObjects = (array) => {
    return array.reduce((acc, obj) => {
      const existing = acc.find((o) => o.name === obj.name)
      if (existing) {
        existing.learnerCourses = Array.from(
          new Set([...existing.learnerCourses, ...obj.learnerCourses]),
        )
      } else {
        acc.push({ ...obj })
      }
      return acc
    }, [])
  }

  const mergeInProgressObjects = (array) => {
    const allCourses = array.map((obj) => obj.learnerCourses).flat()
    const uniqueCourses = Array.from(new Set(allCourses))

    return {
      name: 'Continue Learning',
      learnerCourses: uniqueCourses,
    }
  }

  useEffect(() => {
    const inProgressMerged = mergeInProgressObjects(inProgressCategories)
    const allMerged = mergeAllObjects([
      ...inProgressCategories,
      ...toDoCategories,
      ...completedCategories,
    ])

    let categories: CourseLearnerCategory[]
    switch (tabSelected) {
      case 0:
        categories = [inProgressMerged, ...allMerged]

        break
      case 1:
        categories = inProgressCategories
        break
      case 2:
        if (categories) {
          categories = [continueCategory, ...toDoCategories]
        } else {
          categories = [...toDoCategories]
        }
        break
      case 3:
        categories = completedCategories
        break
      default:
        categories = []
    }

    setFilteredCategories(
      currentUser.isClientAlias
        ? categoriesForMembership
        : categories?.length > 0
          ? categories
          : [],
    )
  }, [
    completedCategories,
    continueCategory,
    currentUser.isClientAlias,
    inProgressCategories,
    categoriesForMembership,
    tabSelected,
    toDoCategories,
  ])
  //set page category contents
  //if not in support mode

  //update current tab if tab is clicked
  const setTabSelectedCallBack = useCallback(
    (tabTitle) => {
      setTabSelected(
        navTabItems.findIndex((tabItem) => tabTitle === tabItem.title),
      )
    },
    [navTabItems],
  )
  return (
    <>
      <div className="h-[calc(100vh-64px)] overflow-y-scroll flex flex-col">
        <div className="flex flex-col md:flex-row justify-between w-full  items-end p-2 lg:px-6 lg:pb-0 bg-white border-b">
          {isMobile && (
            <SearchGroups
              searchVal={searchVal}
              setSearchVal={setSearchVal}
              localStorageName={localStorageName}
              setSelectedGroups={setSelectedGroups}
              GAEventCategory="Learner"
              isMobile={isMobile}
            />
          )}
          <div className="flex flex-row w-full items-center">
            <FilterNavTabs
              navTabs={navTabItems}
              onTabClickCallBack={setTabSelectedCallBack}
              selectedTab={tabSelected}
              GATracker={{
                category: 'Learner',
                eventName: 'change tab learn courses',
              }}
            />
            {!isMobile && (
              <div className="flex justify-end ml-auto pb-2">
                <SearchGroups
                  searchVal={searchVal}
                  setSearchVal={setSearchVal}
                  localStorageName={localStorageName}
                  setSelectedGroups={setSelectedGroups}
                  GAEventCategory="Learner"
                />
              </div>
            )}
          </div>
        </div>

        {filteredCategories?.length === 0 && (
          <div className="grow p-1 grid place-items-center">
            <Empty title="No Learner Courses Available" defaultIcon />
          </div>
        )}

        {filteredCategories?.length > 0 && (
          <div className={`gap-4 ${isMobile && 'mb-12'}`}>
            {filteredCategories.map(
              (category, index) =>
                category.learnerCourses.length > 0 && (
                  <div
                    key={'category-' + category.id + '-' + index}
                    className="rounded bg-gray-50 px-3 sm:px-6 pb-3 pt-3 sm:pt-6 flex flex-col "
                    id={'category-' + category.id}
                  >
                    <div className="mb-4">
                      <p className="text-xl font-medium text-gray-900">
                        {category.name}
                      </p>
                    </div>
                    <div
                      className={`flex ${
                        isMobile ? 'flex-row' : 'flex-wrap'
                      } overflow-x-auto pt-1`}
                    >
                      {category.learnerCourses?.map((course, index) => (
                        <CourseItem
                          course={course}
                          key={`${category.id}_${course.id}_${index}`}
                        />
                      ))}
                    </div>
                  </div>
                ),
            )}
          </div>
        )}
      </div>
    </>
  )
}

export default CourseCategories
